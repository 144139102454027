import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import ReactMarkdown from 'react-markdown';

const Feature = ({ title, content, image, imageLeft, link }) => (

    <section className="section py-4 overflow-hidden">
        <div className="container">
            <div className="row">

                {imageLeft &&
                    <div className="col-md-5 mx-auto text-center mt-8 mt-md-0">
                        <img src={image} className="rounded shadow-2" alt={title} />
                    </div>
                }
                <div className="col-md-6 align-self-center text-center text-md-left">
                    <>
                    <ReactMarkdown source={title} escapeHtml={false} />
                    <ReactMarkdown source={content} escapeHtml={false} />
                    <br />
                    <Link to={link}><strong>Read more...</strong></Link>
                    </>
                </div>
                {!imageLeft &&
                    <div className="col-md-5 mx-auto text-center mt-8 mt-md-0">
                        <img className="rounded shadow-2" src={image} alt={title} />
                    </div>
                }
            </div>
        </div>
    </section>
)

Feature.propTypes = {
	title: PropTypes.string,
	content: PropTypes.string,
    image: PropTypes.string,
    imageLeft: PropTypes.bool,
    link: PropTypes.string
};

React.defaultProps = {
	image: '',
    imageLeft: false,
    link: '',
};

export default Feature
