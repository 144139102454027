import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import ReactMarkdown from 'react-markdown';

const Testimonial = ({ testimonials }) => (

    <section className="section" style={{backgroundColor: "#b88b72"}}>
        <div className="container">
            <div data-provide="slider" data-autoplay="true">
              <div className="px-6">
                <blockquote className="blockquote">
                  <p className="lead-4 text-white">Design everything on the assumption that people are not heartless or stupid but marvelously capable, given the chance.</p>
                  <br/>
                  <footer className="text-white">John Jones</footer>
                </blockquote>
              </div>
            </div>
        </div>
    </section>
)

Testimonial.propTypes = {
	testimonials: PropTypes.object
};

export default Testimonial
