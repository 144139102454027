import React, {Component} from "react";
import PropTypes from 'prop-types';
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import Header from '../components/header';
import Feature from '../components/feature';
import Testimonials from '../components/testimonials';
import ReactMarkdown from 'react-markdown';
import Img from 'gatsby-image';
import { CardGroup, Card, CardImg, CardBody, Row, Col, Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
// import logoLight from '../assets/images/logo-light.png'
import 'react-dates/initialize';
import { ScrollableOrientationShape, DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import Metatags from '../components/Metatags';

class HomeLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            props: props,
            focusedInput: null,
            startDate: null,
            endDate: null,
            page: props.data.dataJson,
            guests: 2,
            rooms: 1,
            arrival: new Date(),
            departure: new Date()+1
        }
        console.log(props);
    }
    componentDidMount() {
        var date = new Date();
        var arrival = date.getDate() + "/" + date.getMonth()+1 + "/" + date.getFullYear();
        date.setDate(date.getDate() + 1);
        var departure = date.getDate() + "/" + date.getMonth()+1 + "/" + date.getFullYear();
        this.setState({arrival: arrival, departure: departure});

    }



    render() {
      return (

          <Layout>
          <Metatags
                   title={this.state.page.meta.metatitle}
                   keywords={this.state.page.meta.metakeywords}
                   description={this.state.page.meta.metadescription}
                   thumbnail={this.state.page.meta.metaimage}
                   url={this.state.page.meta.metaurl}
                   pathname={this.state.page.meta.metapathname}
               />
          <header className="header text-white h-fullscreen" style={{backgroundColor: "#78909c", backgroundImage: `url(${this.state.page.titleBackground})`}} >
              <div className="overlay opacity-30" style={{backgroundColor: "#b88b72"}}></div>
              <div className="container text-center">

                    <div className="row h-100">
                      <div className="col-lg-12 mx-auto align-self-center">

                        <h1 className="lead-9">{this.state.page.title}</h1>
                        <h3 >{this.state.page.subTitle}</h3>


                       </div>
                       <div className="mx-auto align-self-center">
                      </div>
                    </div>

                  </div>
                <div className="container text-center">
                  <div className="row">
                    <div className="col-md-8 mx-auto">

                    </div>
                  </div>
                </div>
          </header>
          <main className="main-content">
               <section className="section pb-6">
                  <div className="container">
                      <div className="row">

                      <div className="col-md-6">
                        <div className="card shadow-4">
                          <div className="card-body">
                          <Img className="rounded shadow-2" sizes={this.state.props.data.blueRoom.childImageSharp.sizes} className="w-100" alt="Twin Room" />
                          <CardBody>
                            <h2>Large Double Room</h2>
                            <p>For when you would like to share with a sibling or friend!</p>

                            <a className="fs-12 fw-600" href="/rooms">Read more <i className="fa fa-angle-right pl-1"></i></a>
                          </CardBody>
                          </div>
                        </div>
                      </div>


                      <div className="col-md-6">
                        <div className="card shadow-4">
                          <div className="card-body">
                          <Img sizes={this.state.props.data.roseRoom.childImageSharp.sizes} className="w-100" alt="Bouble Room" />
                          <CardBody>
                            <h2>Double Room</h2>
                            <p>The affordable, yet comfortable option!</p>
                            <a className="fs-12 fw-600" href="/rooms">Read more <i className="fa fa-angle-right pl-1"></i></a>
                          </CardBody>
                          </div>
                        </div>
                      </div>


                      </div>
                    </div>
                  </section>
                  <section className="section py-7" style={{backgroundColor: "#b88b72"}}>
                    <div className="container">
                      <div className="row gap-y align-items-center">
                        <div className="col-md-9">
                          <h4 className="mb-0 text-white text-center text-md-left">Experience our hospitality. Book Now!</h4>
                        </div>

                        <div className="col-md-3 text-center text-md-right">
                          <Link className="btn btn-lg btn-dark" to="/booknow">Book Now</Link>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="section pt-4">
                      <div className="container">
                          <div className="row">
                              <div className="mx-auto">
                                {this.state.page.contents.map((item, index) => (
                                    <Feature title={item.heading} content={item.content} image={item.image} imageLeft={item.imageLeft} link={item.link} />
                                ))}
                                  <br/>
                              </div>
                          </div>
                      </div>
                  </section>
                  <Testimonials testimonials={[]}/>
              </main>
          </Layout>
      )
  }
}

export const query = graphql`
  query($slug: String!) {
    dataJson(fields: { slug: { eq: $slug } }) {
      title
      subTitle
      titleBackground
      contents {
         heading
         image
         imageLeft
         content
         link
       }
       meta {
           metadescription
       metakeywords
       metatitle
       metaimage
       metaurl
       metapathname
       }

     }
    twinRoom: file( relativePath: {eq: "images/twin-room.jpg"}) {
        childImageSharp {
            ... on ImageSharp {
                sizes(maxWidth: 350) {
                    ...GatsbyImageSharpSizes
                }
            }
        }
    }
    roseRoom: file( relativePath: {eq: "images/doubleroom.jpg"}) {
        childImageSharp {
            ... on ImageSharp {
                sizes(maxWidth: 350) {
                    ...GatsbyImageSharpSizes
                }
            }
        }
    }
    blueRoom: file( relativePath: {eq: "images/yellow-room.jpg"}) {
        childImageSharp {
            ... on ImageSharp {
                sizes(maxWidth: 350) {
                    ...GatsbyImageSharpSizes
                }
            }
        }
    }
  }
`

HomeLayout.propTypes = {
	data: PropTypes.object
};

React.defaultProps = {
	className: 'react-remark',
};




export default HomeLayout;
